import isDevelopment from 'lib/isDevelopment';
import Cookies from 'js-cookie';

export const gtmClickElementIds = {
  header: {
    header: 'Header',
    headerDropdownBanner: 'Header Dropdown Banner',
    headerDropdownParentLink: 'Header Dropdown Parent Link',
    headerDropdownChildLink: 'Header Dropdown Child Link',
    account: 'Header Account',
    wishlist: 'Header Wishlist',
  },
  sectionHero: 'Section - Hero',
  sectionBanner: 'Section - Banner',
  componentGridBanner: 'Component - Grid Banner',
};

// Datalayer

const getClient = () => {
  if (typeof window !== 'undefined') {
    if (!Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }
    return window;
  }
  return false;
};

export const trackInWindow = (...args) => {
  const client = getClient();

  if (isDevelopment || ['development', 'preview'].includes(process.env.VERCEL_ENV))
    console.log('ℹ️ Tracking', ...args);

  if (client) client.dataLayer.push(...args);
};

export const gaHandler =
  typeof window !== 'undefined' && window.ga
    ? window.ga
    : function () {
        // eslint-disable-next-line prefer-rest-params
        getClient()?.dataLayer.push(arguments);
      };

export const getDataLayer = () => {
  const client = getClient();
  if (client) return client.dataLayer;
  return [];
};

export const getAlgoliaUserTokenFromGACookie = () => {
  return `guest-${Cookies?.get('_ga')?.match(/GA\d+\.\d+\.(\d+)\.\d+/)[1]}`;
};

export const trackEvents = {
  applyFilter: 'apply_filter',
  componentBannersClick: 'component_banners_click',
  createAccount: 'create_account',
  fitGuide: 'fit_guide',
  login: 'login',
  newsletterSignUpLegacy: 'newsletter_sign-up',
  newsletterSignUp: 'newsletter_sign_up',
  outboundLinkClick: 'outbound_link_click',
  search: 'ap_search',
  hitsViewed: 'Hits Viewed',
  routeChange: 'route_change',
  viewVideo: 'view_video',
  shopTheLook: 'ap_shop_the_look',
  swipeTheLook: 'ap_swipe_look',
  trackOrder: 'ap_track_order',
  componentBannerClick: 'ap_component_clicked',
  startChat: 'ap_message_sent',
  downloadApp: 'ap_download_app',
  purchase: 'EECpurchase',
  serverExperiment: 'server_experiment_impression',
  shopTheLookEngagement: 'shop_the_look_engagement',

  // w/ items
  viewCart: 'ap_view_cart',
  addToCart: 'add_to_cart',
  removeFromCart: 'remove_from_cart',
  beginCheckout: 'begin_checkout',
  productPageView: 'view_item',
  collectionPageView: 'view_item_list',
  addToWishlist: 'add_to_wishlist',
  removeFromWishlist: 'remove_from_wishlist',
  notifyMeClick: 'notify_me_click',
  productClick: 'product_click',
  productClickInCarousel: 'product_recommendation_click',
  completeTheLook: 'ap_complete_the_look',
  sizeChart: 'size_chart',
};
